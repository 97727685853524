import { Product } from '@entities/product';
import { ProductType } from '@entities/enums/product-type';
import { PlanInterval } from '@entities/enums/plan-interval';

const products: Product[] = [
   // {
   //    name: 'Process',
   //    id: 'prod_GRGdFoecUQVYtT',
   //    type: ProductType.Process,
   //    plans: [
   //       { id: 'plan_GRGdoNqF6r0qMm', interval: PlanInterval.MONTHLY, price: 10 },
   //       { id: 'plan_GRGdw63DfIuPtb', interval: PlanInterval.YEARLY, price: 100 }
   //    ]
   // },
   // {
   //    name: 'Evolve',
   //    id: 'prod_GRGdFoecUQVYtT',
   //    type: ProductType.Evolve,
   //    plans: [
   //       { id: 'plan_GRGdDJ5m6UTCRg', interval: PlanInterval.MONTHLY, price: 10 },
   //       { id: 'plan_GRGgKqSgbYn9Ez', interval: PlanInterval.YEARLY, price: 100 }
   //    ]
   // },
   {
      name: 'Complete',
      id: 'prod_GRGdFoecUQVYtT',
      type: ProductType.Complete,
      plans: [
         { id: 'plan_GRGdCATfFbKptK', interval: PlanInterval.MONTHLY, price: 15 },
         { id: 'plan_GRGdk61iUF2a39', interval: PlanInterval.YEARLY, price: 150 },
      ],
   },
   {
      name: 'Reference',
      id: 'prod_GRGdFoecUQVYtT',
      type: ProductType.Reference,
      plans: [
         { id: 'price_1ICsQTEVIHp5fNwtlTdatRAf', interval: PlanInterval.MONTHLY, price: 5 },
         { id: 'price_1ICsQrEVIHp5fNwttzuehAX9', interval: PlanInterval.YEARLY, price: 50 },
      ],
   },
];

export const environment = {
   auth0: {
      client: 'FmCL2vTUlfESgHlOFE28h9czP0mq32YT',
      domain: 'opsmgr.auth0.com',
      redirectUri: 'https://app.alignedorgs.com/callback',
      logoutUrl: 'https://app.alignedorgs.com',
      realm: 'OpsMgrProd',
   },
   isConfig: true,
   production: true,
   apiUrl: 'https://alignedapps.azurewebsites.net/api',
   baseUrl: 'https://opsmgr.alignedapps.com',
   demoOrg: 'PndWH1ny0qGgHmwgk9zD',
   name: '',
   firebase: {
      apiKey: 'AIzaSyD5yQjO6ZIhm2PKSAI0uefuH_UjN-Uefjs',
      authDomain: 'aligned-orgs.firebaseapp.com',
      databaseURL: 'https://aligned-orgs.firebaseio.com',
      projectId: 'aligned-orgs',
      storageBucket: 'aligned-orgs.appspot.com',
      messagingSenderId: '596408135750',
      appId: '1:596408135750:web:7c6e31e3145caeb9',
      measurementId: 'G-ER46FLW4ML',
   },
   logrocket: {
      init: 'dqnbv6/orgaligner',
   },
   sentry: {
      dsn: 'https://1c532cc415fa479a8acf974d232e16b3@sentry.io/1509953',
   },
   stripe: {
      publicKey: 'pk_live_rMPlbpfFlcdFoy3hbCOZvzUC00RWHdG2Tz',
      products,
   },
};
